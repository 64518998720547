<template>
    <svg 
        :width="width" 
        :height="height" 
        viewBox="0 0 16 16" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle 
            cx="8" 
            cy="8" 
            r="6" 
            fill="white" 
            stroke="#C1C1C1" 
            stroke-width="0.5" 
        />
        <path 
            d="M10.5477 5.45312L5.45508 10.5457" 
            :stroke="strokeColor" 
            stroke-width="1.33333" 
            stroke-linecap="round"
            stroke-linejoin="round" 
        />
        <path 
            d="M5.45508 5.45312L10.5477 10.5457" 
            :stroke="strokeColor" 
            stroke-width="1.33333" 
            stroke-linecap="round"
            stroke-linejoin="round" 
        />
    </svg>
</template>

<script>
export default {
    props: {
        width: {
            type: Number,
            default: 16
        },
        height: {
            type: Number,
            default: 16
        },
        strokeColor: {
            type: String,
            default: "#929292"
        }
    }
};
</script>

<style></style>
